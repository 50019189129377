import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from 'src/environments/environment';

import { AddUserModel } from '../models/users/add-user-model';
import { IUserList } from '../interfaces/users/user-list';
import { IUserEdit } from '../interfaces/users/user-edit';
import { IChangePassword } from '../interfaces/users/change-password';
import { EditUserModel } from '../models/users/edit-user-model';
import { ChangePasswordModel } from '../models/users/change-password-model';
import { ISelectResult } from '../interfaces/select-result';
import { IPage } from '../interfaces/page';
import { QueryPageModel } from '../models/query-page-model';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private apiPath = environment.apiUrl + 'users/';

  constructor(
    private http: HttpClient
  ) { }

  getAll(): Observable<IUserList[]> {
    return this.http.get<IUserList[]>(this.apiPath)
      .pipe(map(result => {
        return result;
      }));
  }

  getPage(model: QueryPageModel): Observable<IPage<IUserList>> {
    var headers = new HttpHeaders({
      'Content-Type': 'application/json',
    })
    return this.http.post<IPage<IUserList>>(this.apiPath + 'page', JSON.stringify(model), { headers: headers })
      .pipe(map(result => {
        return result;
      }));
  }

  count(): Observable<number> {
    return this.http.get<number>(this.apiPath + 'count')
      .pipe(map(result => {
        return result;
      }));
  }

  add(model: AddUserModel): Observable<any> {
    var headers = new HttpHeaders({
      'Content-Type': 'application/json',
    })
    return this.http.post<any>(this.apiPath, JSON.stringify(model), { headers: headers })
      .pipe(map(result => {
        return result;
      }));
  }

  get(id: string): Observable<IUserEdit> {
    return this.http.get<IUserEdit>(this.apiPath + id)
      .pipe(map(result => {
        return result;
      }));
  }

  update(id: string, model: EditUserModel): Observable<any> {
    var headers = new HttpHeaders({
      'Content-Type': 'application/json',
    })
    return this.http.put<any>(this.apiPath + id, JSON.stringify(model), { headers: headers })
      .pipe(map(result => {
        return result;
      }));
  }

  delete(id: string): Observable<any> {
    return this.http.delete<any>(this.apiPath + id)
      .pipe(map(result => {
        return result;
      }));
  }

  getChangePassword(id: string): Observable<IChangePassword> {
    return this.http.get<IChangePassword>(this.apiPath + id + '/change-password')
      .pipe(map(result => {
        return result;
      }));
  }

  changePassword(id: string, model: ChangePasswordModel): Observable<any> {
    var headers = new HttpHeaders({
      'Content-Type': 'application/json',
    })
    return this.http.put<any>(this.apiPath + id + '/change-password', JSON.stringify(model), { headers: headers })
      .pipe(map(result => {
        return result;
      }));
  }

  getForOfficeWithoutCurrentAssignee(): Observable<ISelectResult[]> {
    return this.http.get<ISelectResult[]>(this.apiPath + 'for-office')
      .pipe(map(result => {
        return result;
      }));
  }

  getForOfficeWithCurrentAssignee(assignToId: string): Observable<ISelectResult[]> {
    return this.http.get<ISelectResult[]>(this.apiPath + 'for-office/' + assignToId)
      .pipe(map(result => {
        return result;
      }));
  }
}
